/*
Table Of Contents
=========================
- Default Typography
- Custom Typography
=========================
*/


/*
--------------------------
- Default Typography
--------------------------
*/

body {
    font-family: "Poppins",sans-serif;
    font-size: 12px;
    color: #283376;
}

body > b {
    font-weight: 500 !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    font-family: 'Poppins', sans-serif;
}
p{
    font-family: 'Poppins', sans-serif;
    font-size: 15px;
    line-height: 26px;
    color: #444;
    margin-bottom: 0;
}
p img {
    margin: 0;
}

/* links */

a,
a:visited {
    text-decoration: none;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    outline: 0;
    font-family: 'Poppins', sans-serif;
}
a:hover{
    text-decoration: none;
}
a:focus {
    text-decoration: none;
    outline: 0;
}

p a,
p a:visited {
    line-height: inherit;
    outline: 0;
}


/* list */

ul,
ol {
    margin-bottom: 0px;
    margin-top: 0px;
    padding: 0;
}

ul {
    margin: 0;
    list-style-type: none;
}

ol {
    list-style: decimal;
}

ol,
ul.square,
ul.circle,
ul.disc {
    margin-left: 0px;
}

ul.square {
    list-style: square outside;
}

ul.circle {
    list-style: circle outside;
}

ul.disc {
    list-style: disc outside;
}

ul ul,
ul ol,
ol ol,
ol ul {
    margin: 0;
}

ul ul li,
ul ol li,
ol ol li,
ol ul li {
    margin-bottom: 0px;
}

button {
    cursor: pointer;
    outline: none!important;
    letter-spacing: 0;
}

/*
--------------------------
- Custom Typography
--------------------------
*/
/* blockquote */
blockquote{
    padding: 60px;
    position: relative;
    background: transparent;
    display: inline-block;
}
blockquote:before{
    content: '\f10d';
    font-family: fontawesome;
    color: #fff;
    font-size: 32px;
    position: absolute;
    left: 16px;
    top: 46px;
}
blockquote p{
    font-size: 17px;
    color: #fff;
}
.blockquote-footer{
    color: #fff;
}