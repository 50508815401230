@import "bootstrap.min.css";
@import "default-css.css";
@import "font-awesome.min.css";
@import "metisMenu.css";
/*@import "owl.carousel.min.css";*/
@import "responsive.css";
@import "slicknav.min.css";
@import "styles.css";
@import "themify-icons.css";
@import "typography.css";
@import "daterangepicker.css";
@import "bootstrap-datepicker.min.css";
@import "bootstrap-datepicker.standalone.min.css";
@import "bootstrap-datepicker3.min.css";
@import "bootstrap-datepicker3.standalone.min.css";
@import "jquery-ui.css";